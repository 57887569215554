<template>
  <header class="w-full">
    <cookie-law
      v-on:accept="setAnal()"
      theme="blood-orange--rounded"
      v-if="
        datas && datas.custom.banner_disable_cookie == false && datas.tracking
      "
      storageName="cookies"
    >
      <div class="cookie-banner" slot-scope="props">
        <div>
          Ce site web stocke des cookies sur votre ordinateur. Ces cookies sont
          utilisés pour collecter des informations sur la façon dont vous
          interagissez avec notre site Web et nous permettent de nous souvenir
          de vous. Nous utilisons ces informations afin d'améliorer et de
          personnaliser votre expérience de navigation et pour des analyses et
          des mesures sur nos visiteurs à la fois sur ce site Web et d'autres
          médias. Pour en savoir plus sur les cookies que nous utilisons,
          consultez
          <a :href="`${datas.politique_uri}`" target="_blank" rel="noopener">{{
            datas.langText.confidentialiteLink
          }}</a
          >. <br /><br />
          Si vous refusez, vos informations ne seront pas suivies lorsque vous
          visitez ce site Web. Un seul cookie sera utilisé dans votre navigateur
          pour mémoriser votre préférence de ne pas être suivi.
          <br />
          <br />
        </div>
        <div>
          <button class="skew" @click="props.accept">
            <span>J'accepter</span>
          </button>
          <button class="skew" @click="props.close">
            <span>Je refuse</span>
          </button>
        </div>
      </div>
    </cookie-law>
  </header>
</template>
<script>
import CookieLaw from "vue-cookie-law";

export default {
  name: "foot",
  components: { CookieLaw },
  data: function () {
    return {
      datas: this.$root.configOp.datas,
      id: this.$root.configOp.datas.operation_id,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  mounted() {
    if (this.datas.tracking && localStorage.getItem("cookies") === true) {
      this.setAnal();
    }
  },
  methods: {
    setAnal() {
      if (this.datas.tracking) {
        const script = document.createElement("script");
        script.async = true;
        script.src =
          "https://www.googletagmanager.com/gtag/js?id=" + this.datas.tracking;

        script.addEventListener("load", () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", this.datas.tracking);
        });
        document.head.append(script);
      }
    },
  },
};
</script>

<style lang="scss">
.Cookie {
  background-color: var(--primary-color-bg) !important;
  background: var(--primary-color-bg) !important;
  color: var(--primary-color-txt) !important;
  font-size: 0.8em;
  line-height: 1.5em;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  left: unset !important;
  right: 5% !important;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    left: 0 !important;
    right: unset !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.75em;
  }

  a {
    color: var(--primary-color-txt) !important;
  }

  ul {
    list-style-type: none !important;
  }

  button {
    font-size: 1.1em;
    margin-right: 20px;
    font-weight: 400;
    min-width: 150px;

    @media screen and (max-width: 768px) {
      width: 100px;
      margin-right: 10px;
    }
  }
}
</style>
