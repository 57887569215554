import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/404",
      alias: "/:_id/404",
      name: "404",
      component: () => import("./views/404.vue"),
    },
    {
      path: "/justificatif/:_sub_id/:_key",
      alias: "/:_id/justificatif/:_sub_id/:_key",
      name: "justificatif",
      component: () => import("./views/Justificatif.vue"),
    },
    {
      path: "/status/:_sub_id/:_key",
      alias: "/:_id/status/:_sub_id/:_key",
      name: "status",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/",
      alias: "/:_id",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
  ],
});

function gtag() {
  window.dataLayer.push(arguments);
}

export default router;
router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();

  let data = router.app.configOp.datas;

  if (router.app.configOp.datas) {
    if (data.tracking && localStorage.cookies) {
      let name = to.name || "home";
      window.dataLayer = window.dataLayer || [];

      gtag("js", new Date());
      gtag("config", data.tracking, {
        send_page_view: false,
      });
      gtag("event", "page_view", {
        page_title: name,
        page_location: window.location.href,
        page_path: to.path,
      });
    }

    if (data.local_link == "") {
      /* FORCE HTTPS */
      if (location.protocol !== "https:") {
        // window.location =
        //   "https://" +
        //   window.location.hostname +
        //   window.location.pathname +
        //   window.location.search;
      }
    } else {
      next();
    }
  }

  if (!router.app.configOp.datas && to.name != "404") {
    next("/404");
  } else {
    next();
  }
});
