import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

import "@/scss/templates/default.scss";

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import { Init } from "./libs/init.js";

var init = Init();

init.then((init) => {
  Vue.config.productionTip = false;

  new Vue({
    data: {
      configOp: {
        datas: init,
      },
    },
    router,
    pinia,
    render: (h) => h(App),
  }).$mount("#apps");
});
