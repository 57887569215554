<template>
  <transition name="fade" :show="isOpen">
    <div
      class="fixed z-50 inset-0 flex justify-center items-center"
      v-if="isModalVisible"
    >
      <div
        @click="onToggle"
        class="absolute bg-black opacity-70 inset-0 z-0"
      ></div>
      <div
        class="w-full max-w-lg p-3 relative mx-auto my-auto shadow-lg bg-primary text-primary-color rounded-gabarit"
      >
        <div>
          <div class="text-center p-3 flex-auto justify-center leading-6">
            <svg
              fill="none"
              class="w-28 m-auto stroke-red-500"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              ></path>
            </svg>
            <h2 class="text-2xl font-bold py-4" v-html="title" />
            <p class="text-md text-gray-500 px-8" v-html="description"></p>
          </div>
          <div class="p-3 mt-2 text-center space-x-4 md:block">
            <button
              @click="onToggle"
              class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";

export default {
  props: ["title", "description"],
  created() {
    const chickenInstance = {
      info: this.info,
      confirm: this.confirm,
    };
    Vue.prototype.$chicken = chickenInstance;
    console.log("chicken initialised");
  },

  data() {
    return {
      isOpen: false,
      iconSrc: "",
      text: "",

      type: undefined,
      time: 0,
      confirmButtonText: "Oui",
      showConfirmButton: true,
      cancelButtonText: "Non",
      showCancelButton: false,
      open: false,
      promiseResolver: undefined,
    };
  },

  computed: {
    isModalVisible() {
      return this.isOpen;
    },
  },

  methods: {
    info(text, { iconSrc, confirmButtonText, timer, type } = {}) {
      this.showCancelButton = false;
      this.iconSrc = iconSrc;
      this.timer = timer || 0;
      this.confirmButtonText = confirmButtonText || "OK";
      this.text = text;
      this.type = type || "";
      this.open = true;
      if (timer > 0) {
        setTimeout(() => {
          this.open = false;
        }, timer);
      }
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
